import styled from "styled-components";

import vars from "variables";

import down from "assets/Dashboard/down.svg";

const Root = styled.div`
  font-size: 0.8rem;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;

  strong {
    padding-right: 15px;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    background: url(${down});
    background-repeat: no-repeat;
    background-position: right center;
  }

  .dropdown {
    right: -19px;
    padding: 14px 20px;
    display: none;
    position: absolute;
    background: #000317;
    border-radius: 6px;
    z-index: 999999;
    &:after {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-bottom: 7px solid #000317;
      border-right: 7px solid transparent;
      border-top: 7px solid transparent;
      border-left: 7px solid transparent;
      top: -14px;
      right: 16px;
    }
  }

  .dropdown.open {
    display: block;
  }

  ul {
    position: relative;
    z-index: 9999999;
  }

  li {
    cursor: pointer;
    opacity: 0.5;
  }

  li:hover {
    opacity: 1;
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    li {
      line-height: 1.9rem;
    }

    .dropdown {
      top: 35px;
      right: 8px;
      &::after {
        right: 45px;
      }
    }
  }
`;

export { Root };
