import styled from "styled-components";

import vars from "variables";

import logo from "assets/icon.png";

import watermark from "assets/Common/Logo/hodl.svg";

const Root = styled.div`
  flex-grow: 1;
  position: relative;

  .flex {
    justify-content: space-between;
    position: relative;
  }

  strong.hodl {
    padding-left: 25px;
    color: #757575;
    display: inline-block;
    letter-spacing: 0.5px;
    background: url(${logo}) no-repeat left center;
    background-size: 18px;
  }

  h2 {
    margin-top: 2px;
  }

  h2 span {
    font-size: 2rem;
  }

  .change {
    display: inline-block;
    position: relative;
  }

  b {
    padding: 3px 8px;
    color: ${vars.colors.marineBlue};
    font-weight: bold;
    font-size: 0.65rem;
    line-height: 1;
    position: absolute;
    border-radius: 3px;
  }

  b.positive {
    background: #50e89d;
  }

  b.negative {
    background: #e85050;
  }

  .switch {
    left: 0;
    bottom: 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    position: absolute;
    background: ${vars.colors.marineBlue};
  }

  .switch a {
    padding: 0 30px;
    display: inline-block;
    cursor: pointer;
  }

  .switch .selected {
    color: ${vars.colors.blue};
  }

  .chart {
    width: calc(100% + 79px);
    margin-left: -40px;
    margin-right: -45px;
    padding-bottom: 20px;
    position: relative;
  }

  .chart:hover .tooltip {
    display: inline-block;
  }

  .chart canvas {
    width: calc(100% + 2px) !important;
  }

  .watermark {
    right: 28px;
    bottom: 90px;
    width: 15%;
    height: 200px;
    position: absolute;
    opacity: 0.15;
    pointer-events: none;
    background: url(${watermark}) no-repeat bottom right;
    background-size: contain;
  }

  .tooltip {
    margin-left: -133px;
    margin-top: -100px;
    width: 250px;
    height: 57px;
    padding-top: 22px;
    display: none;
    line-height: 175%;
    position: absolute;
    text-align: center;
    background-color: ${vars.colors.background};
    border-radius: 12px;
    opacity: 1 !important;
    z-index: 6;
    &:after {
      position: absolute;
      content: "";
      width: 0px;
      height: 0px;
      border-top: 8px solid ${vars.colors.background};
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      bottom: -16px;
      left: 50%;
    }
  }

  .tooltip label {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }

  .tooltip small {
    color: ${vars.colors.grey};
    font-size: 0.7rem;
    letter-spacing: 1.5px;
    opacity: 0.7;
    text-transform: uppercase;
  }

  .tooltip div {
    left: 50%;
    bottom: -30px;
    width: 11px;
    height: 11px;
    position: absolute;
    background: ${vars.colors.blue};
    border: 3px solid ${vars.colors.marineBlue};
    border-radius: 100px;
  }

  ${vars.desktop.mediaQuery} {
    h2 {
      margin-bottom: 75px;
      font-size: 2.8rem;
      font-weight: 500;
    }

    b {
      top: -24px;
      left: 12px;
    }
  }

  ${vars.mobile.mediaQuery} {
    h2 {
      margin-top: 5px;
      font-size: 1.85rem;
      font-weight: 400;
    }

    b {
      top: -17px;
      left: 20px;
    }

    .tooltip {
      margin-top: -91px;
      margin-left: -107px;
      width: 200px;
      height: 50px;
      padding-top: 17px;
    }

    .tooltip label {
      font-size: 0.85rem;
      line-height: 1.1rem;
    }

    .tooltip small {
      font-size: 0.65rem;
    }

    .tooltip div {
      width: 8px;
      height: 8px;
    }

    .chart {
      width: calc(100% + 48px);
      margin-left: -24px;
    }

    .chart canvas {
      height: 100vw !important;
    }

    .watermark {
      bottom: 55px;
      right: 14px;
      width: 27%;
    }

    .switch a {
      padding: 0 20px;
    }
  }
`;

export { Root };
