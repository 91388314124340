import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  span.green {
    color: ${vars.colors.green};
  }

  span.red {
    color: ${vars.colors.red};
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    strong {
      margin-bottom: 9px;
      display: block;
    }

    label {
      margin-bottom: 3px;
      display: block;
    }
  }
`;

export { Root };
