import React, { useEffect, useRef, useState } from 'react';
import { Root } from './style';

import Layout from 'components/Layout/Dashboard';
import FlipBox from 'components/Common/FlipBox';
import SiteData from 'components/Common/SiteData';
import Stats from 'components/Index/Stats';

import Countdown from './Countdown';
import PriceCharts from './PriceCharts';
import HodlMarkets from './HodlMarkets';
import TodaysMarket from './TodaysMarket';
import TransactionTaxes from './TransactionTaxes';

import moment from 'moment';

import {
  YEAR_SECONDS,
  currentRank,
  formatHODL,
  formatNumber,
  inWindow,
  nextRank,
  prevRank,
} from 'utils';

import { useDashboard } from 'dashboard';
import { useWeb3, INJECTED } from 'web3';

import vars from 'variables';

const Dashboard = ({ location }) => {
  const [equity, setEquity] = useState(0);
  const [position, setPosition] = useState(0);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState('pancakeswap');

  const { addWalletAddress } = useDashboard();
  const { bsc, connect } = useWeb3();

  const {
    addresses,
    balance,
    claimableBNB,
    rewarded,
    reflected,
    reflections,
    reinvestedHODL,
  } = useDashboard();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const address = searchParams.get('a');

    if (address) {
      connect(INJECTED).then(() => {
        addWalletAddress(address);
      });
      const style = document.createElement('style');
      style.innerHTML = `
        .compact .scalable {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(addresses).length) {
        fetch(`${vars.apiHost}/v2/-/position/${balance}`)
          .then((response) => response.json())
          .then((data) => {
            setEquity(data.equity);
            setPosition(data.position);
            setTotal(data.total);
          });
      }
    }, 250);
  }, [addresses, balance]);

  const [yearlyForecast, setYearlyForecast] = useState(0);

  useEffect(() => {
    const firstReflection = Object.entries(addresses.get())
      .reduce(
        (acc, [address, selected]) =>
          acc.concat(selected ? reflections[address] || [] : []),
        []
      )
      .filter((tx) => tx)
      .sort(
        (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix()
      )[0];

    if (firstReflection) {
      const timestamp = moment(firstReflection.timestamp).unix();
      const now = Math.round(new Date().getTime() / 1000);
      const days = (now - timestamp) / (24 * 3600);
      const dayAverage = reflected / days;

      setYearlyForecast(dayAverage * 365);
    } else {
      setYearlyForecast(0);
    }
  }, [addresses, reflected, reflections]);

  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Root>
            <div data-row-count="4">
              <FlipBox>
                <div>
                  <h4 className="hodl">$HODL Balance</h4>
                  <strong>{formatNumber(balance, 0)}</strong>
                  <small>${formatNumber(balance * bsc.hodlUsdPrice, 2)}</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewHodlBalance"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4 className="bnb">BNB Rewarded</h4>
                  <strong>{formatNumber(rewarded, 3)} BNB</strong>
                  <small>${formatNumber(rewarded * bsc.bnbUsdPrice, 2)}</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewBnbRewarded"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4 className="hodl">Reflections earned</h4>
                  <strong>{formatNumber(reflected, 0)}</strong>
                  <small>
                    $
                    {formatNumber(
                      reflected * ((bsc.usdPrices || {})['hodl'] || 0),
                      2
                    )}
                  </small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewReflectionsEarned"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4 className="hodl">$HODL from reinvestment</h4>
                  <strong>{formatNumber(reinvestedHODL, 0)}</strong>
                  <small>
                    $
                    {formatNumber(
                      reinvestedHODL * ((bsc.usdPrices || {})['hodl'] || 0),
                      2
                    )}
                  </small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewHodlFromReinvestment"
                  />
                </div>
              </FlipBox>
            </div>
            <div data-row-count="4">
              <FlipBox>
                <div>
                  <Countdown />
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewNextCollection"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div className="rank">
                  <img src={currentRank(balance).image} alt="balance" />
                  <strong>{currentRank(balance).name}</strong>
                  <small>LVL. {currentRank(balance).level}</small>
                  {currentRank(balance).level === 12 ? (
                    <>
                      <div className="progress">
                        <div
                          style={{
                            width: '100%',
                          }}
                        ></div>
                      </div>
                      <small className="remaining">
                        <div>Congrats. You’re king of the ocean!</div>
                      </small>
                    </>
                  ) : (
                    <>
                      <div className="progress">
                        <div
                          style={{
                            width: `${
                              ((balance -
                                prevRank(balance).thresholdAmount *
                                  prevRank(balance).thresholdFactor) /
                                (nextRank(balance).thresholdAmount *
                                  nextRank(balance).thresholdFactor -
                                  prevRank(balance).thresholdAmount *
                                    prevRank(balance).thresholdFactor)) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <small className="remaining">
                        <div>
                          {formatHODL(
                            nextRank(balance).thresholdAmount *
                              nextRank(balance).thresholdFactor -
                              balance
                          )}{' '}
                          $HODL needed
                        </div>
                        <div>LVL. {nextRank(balance).level}</div>
                      </small>
                    </>
                  )}
                </div>
                <div>
                  <SiteData className="info" path="dashboard.overviewLevel" />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4>Current $HODL APR</h4>
                  <strong>
                    {formatNumber(
                      (((YEAR_SECONDS / bsc.rewardClaimPeriod) *
                        claimableBNB *
                        bsc.bnbUsdPrice +
                        yearlyForecast * bsc.hodlUsdPrice) /
                        (balance * bsc.hodlUsdPrice || 1)) *
                        100,
                      3
                    )}
                    %
                  </strong>
                  <small>Rewards and reflections!</small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewCurrentHodlApy"
                  />
                </div>
              </FlipBox>
              <FlipBox>
                <div>
                  <h4>Equity in $HODL</h4>
                  <strong>{((equity || 0) * 100).toFixed(4)}%</strong>
                  <small>
                    {formatNumber(position)} of {formatNumber(total)} hodlers
                  </small>
                </div>
                <div>
                  <SiteData
                    className="info"
                    path="dashboard.overviewEquityInHodl"
                  />
                </div>
              </FlipBox>
            </div>
            <Stats />
            <div className="numbers">
              <PriceCharts selected={selected} setSelected={setSelected} />
              <div className="figures">
                <div className="numbers">
                  <HodlMarkets />
                  <TodaysMarket />
                </div>
                <TransactionTaxes
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </div>
          </Root>
        </>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Dashboard;
