import React, { useEffect } from "react";
import { Root } from "./style";

import { useState } from "@hookstate/core";
import { Link } from "gatsby";

import { useDashboard } from "dashboard";

import vars from "variables";

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const Countdown = () => {
  const { claimEpochs } = useDashboard();

  const index = useState(0);
  const countdown = useState(false);
  const canCollect = useState(false);

  const onSwitchClick = (event, i) => {
    event.preventDefault();
    index.set(i);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const epochs = claimEpochs.get();
      const claimEpoch = epochs[index.get()] && epochs[index.get()].epoch;

      let count = false;
      let collect = false;

      if (claimEpoch) {
        const nowEpoch = new Date().getTime() / 1000;
        const diff = claimEpoch - nowEpoch;
        if (diff > 0) {
          const days = Math.floor(diff / DAY);
          const hours = Math.floor((diff - days * DAY) / HOUR);
          const minutes = Math.floor(
            (diff - days * DAY - hours * HOUR) / MINUTE
          );
          const seconds = Math.floor(
            diff - days * DAY - hours * HOUR - minutes * MINUTE
          );
          count = {
            days,
            hours,
            minutes,
            seconds,
          };
        } else {
          collect = true;
        }
      }
      countdown.set(count);
      canCollect.set(collect);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Root>
      <h4>
        <div>
          <span>Next collection </span>
          {claimEpochs.get().length > 1 ? (
            <span className="truncated">
              {(claimEpochs.get()[index.get()] || {}).address}
            </span>
          ) : (
            ""
          )}
        </div>
      </h4>
      {canCollect.get() ? (
        <div className="collect">
          <span>Ready to collect</span>
          <Link to={vars.pages.claim}>Claim now</Link>
        </div>
      ) : (
        <>
          <div className="countdown">
            <div>
              {countdown.get().days || 0}
              <small>day(s)</small>
            </div>
            <div>:</div>
            <div>
              {countdown.get().hours || 0}
              <small>hour(s)</small>
            </div>
            <div>:</div>
            <div>
              {countdown.get().minutes || 0}
              <small>minute(s)</small>
            </div>
            <div>:</div>
            <div>
              {countdown.get().seconds || 0}
              <small>second(s)</small>
            </div>
          </div>
        </>
      )}
      {claimEpochs.get().length > 1 ? (
        <div className="switch">
          {claimEpochs.get().map((claimEpoch, i) => (
            <span
              key={i}
              className={i === index.get() ? "selected" : ""}
              onClick={(e) => onSwitchClick(e, i)}
              data-noflip
            >
              <span></span>
            </span>
          ))}
        </div>
      ) : (
        ""
      )}
    </Root>
  );
};

export default Countdown;
