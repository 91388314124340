import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  a {
    padding-right: 16px;
    display: inline-block;
    line-height: 16px !important;
    position: relative;
  }

  a:hover {
    border-bottom: 1px solid white;
  }

  a img {
    top: 1px;
    right: -1px;
    height: 12px;
    position: absolute;
  }

  ${vars.desktop.mediaQuery} {
    /* styling */
  }

  ${vars.mobile.mediaQuery} {
    strong {
      margin-top: 3px;
      margin-bottom: 11px;
      display: block;
    }

    label {
      margin-bottom: 3px;
      display: block;
    }
  }
`;

export { Root };
