import React, { useEffect, useState } from "react";
import { Root } from "./style";

import { useSiteData } from "utils";

import link from "assets/Dashboard/link.svg";

import vars from "variables";

const HodlMarkets = () => {
  const { exchanges } = useSiteData().resources;

  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    fetch(`${vars.apiHost}/v2/-/hodl-markets/hodl`)
      .then((response) => response.json())
      .then(setMarkets);
  }, []);

  const exchangeURL = (id) =>
    exchanges.find(({ identifier }) => identifier === id).buyURL;

  return (
    <Root className="box markets">
      <strong>$HODL Markets</strong>
      {exchanges.map(({ identifier, buyURL, usePCSData }) => {
        const price = (markets.find(
          ([exchange]) => (usePCSData ? "pancakeswap" : identifier) === exchange
        ) || [])[1];
        return (
          <div key={identifier}>
            <label>
              <a href={buyURL} target="_blank" rel="noreferrer">
                {identifier}
                <img alt="bscscan" src={link} />
              </a>
            </label>
            <span>${parseFloat(price).toFixed(12)}</span>
          </div>
        );
      })}
    </Root>
  );
};

export default HodlMarkets;
