import React, { useEffect, useState } from "react";
import { Root } from "./style";

import { formatNumber } from "utils";

import vars from "variables";

const TodaysMarket = () => {
  const [figures, setFigures] = useState({});

  useEffect(() => {
    fetch(`${vars.apiHost}/v2/-/market-figures`)
      .then((response) => response.json())
      .then(setFigures);
  }, []);

  return (
    <Root className="box markets">
      <strong>Today's Market</strong>
      <label>BNB Price</label>
      <span>${formatNumber(figures.bnb_price)}</span>
      <label>BNB 24H Change</label>
      <span className={figures.bnb_percent_change_24h < 0 ? "red" : "green"}>
        {formatNumber(figures.bnb_percent_change_24h, 2)}%
      </span>
      <label>BTC Price</label>
      <span>${formatNumber(figures.btc_price)}</span>
      <label>BTC 24H Change</label>
      <span className={figures.btc_percent_change_24h < 0 ? "red" : "green"}>
        {formatNumber(figures.btc_percent_change_24h, 2)}%
      </span>
      <label>Market 24H Volume</label>
      <span>${formatNumber(figures.market_24h_volume, 0)}</span>
    </Root>
  );
};

export default TodaysMarket;
