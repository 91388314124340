import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  .stats {
    margin-bottom: 17px;
    border-radius: 7px;
  }

  .progress {
    margin-top: 20px;
    margin-bottom: 7px;
    height: 4px;
    background: rgb(42, 44, 72);
    border-radius: 7px;
  }

  .progress div {
    height: 4px;
    background: ${vars.colors.blue};
    border-radius: 7px;
  }

  .remaining {
    display: flex;
    font-size: 0.6rem !important;
    justify-content: space-between;
    letter-spacing: 1px;
  }

  .box {
    color: white;
    background: ${vars.colors.marineBlue};
    border-radius: 6px;
  }

  .box strong,
  .box label,
  .box span {
    display: block;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .box strong {
    font-weight: 500;
    letter-spacing: 2px;
  }

  .box label,
  .box span {
    font-weight: 300;
    font-size: 0.9rem;
    letter-spacing: 1px;
  }

  .box span {
    margin-bottom: 15px;
    color: grey;
    line-height: 0.8;
  }

  .flex {
    display: flex;
  }

  ${vars.desktop.mediaQuery} {
    .box {
      padding: 25px 30px 20px 30px;
    }

    .stats ul {
      width: auto !important;
      padding: 40px 40px 45px 40px;
    }

    .numbers {
      padding-bottom: 17px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
    }

    .numbers .price-chart,
    .numbers .figures {
      flex: 0 0 calc(50% - 39px);
    }

    .numbers .figures {
      display: flex;
      flex-direction: column;
    }

    .numbers .markets {
      flex: 0 0 calc(50% - 69px);
    }
  }

  ${vars.mobile.mediaQuery} {
    .box {
      padding: 20px 15px;
    }

    .numbers > div {
      margin-bottom: 17px;
    }

    .stats {
      width: calc(100vw - 28px);
    }

    ul.body-width {
      width: calc(100vw - 60px);
      padding-bottom: 8px;
    }
  }
`;

export { Root };
