import React, { useEffect, useState } from "react";
import { Root } from "./style";

const ExchangePicker = ({ selected, setSelected, exchanges }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", ({ target }) => {
      if (!target.closest(".exchange-picker")) {
        setOpen(false);
      }
    });
  });

  return (
    <Root className="exchange-picker">
      <strong onClick={() => setOpen(!open)}>{selected}</strong>
      <div className={`${open ? "open" : ""} dropdown`}>
        <ul>
          {exchanges.map((exchange, i) => (
            <li
              key={`option${i}`}
              onClick={() => {
                setOpen(!open);
                setSelected(exchange);
              }}
            >
              {exchange}
            </li>
          ))}
        </ul>
      </div>
    </Root>
  );
};

export default ExchangePicker;
